import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './App.css';

function App() {
  const [performances, setPerformances] = useState([]);
  const [performance, setPerformance] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
  const [emoji, setEmoji] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight);
  const navigate = useNavigate();
  const location = useLocation();

  // Update the container height based on the visible viewport height
  useEffect(() => {
    const updateContainerHeight = () => {
      setContainerHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateContainerHeight);
    return () => {
      window.removeEventListener('resize', updateContainerHeight);
    };
  }, []);

  // Fetches the performances data only once when the page loads
  useEffect(() => {
    const fetchPerformances = async () => {
      setIsLoading(true);
  
      const urls = [
        'https://hb-api-server-production.up.railway.app',
        'https://hb-api-server-production.up.railway.app/page2',
        'https://hb-api-server-production.up.railway.app/page3',
        'https://hb-api-server-production.up.railway.app/page4',
        'https://hb-api-server-production.up.railway.app/page5'
      ];
  
      try {
        const allResponses = await Promise.allSettled(urls.map(url => axios.get(url)));
  
        const allData = allResponses
          .filter(response => response.status === "fulfilled")
          .flatMap(response => response.value.data.results);

        const excludeStrings = ["rehearsal", "Park & Ride", "Parking", "Shuttle"];

        const filteredData = allData.filter(data => {
          const isExcluded = excludeStrings.some(excludeStr => 
            data.program.name_markdown.toLowerCase().includes(excludeStr.toLowerCase())
          );
          if (isExcluded) {
            console.log(`Excluded: ${data.program.name_markdown}`);
          }
          return !isExcluded;
        });

        console.log(filteredData)

        setPerformances(filteredData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setIsLoading(false);
      }
    };
  
    fetchPerformances();
  }, []);

  // Updates the performance for the selected date using the stored performances data
  useEffect(() => {
    if (performances.length > 0) {
      const selectedPerformance = performances.find(perf => {
        return perf.perf_time.slice(0, 10) === selectedDate;
      });
      setEmoji(randomEmoji()); // Update the emoji when the performance data is updated
      setPerformance(selectedPerformance);
    }
  }, [performances, selectedDate]);

  // Set the selected date from the URL hash when the component mounts
  useEffect(() => {
    const hashDate = location.hash.slice(1);
    if (hashDate) {
      const [month, day, year] = hashDate.split("-");
      setSelectedDate(`${year}-${month}-${day}`);
    }
  }, [location]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    const [year, month, day] = newDate.split("-");
    navigate(`/#${month}-${day}-${year}`);
  };

  // Formats the selected date to display in the format "May 3, 2023"
  const formattedSelectedDate = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  }).format(new Date(selectedDate.split("-")[0], selectedDate.split("-")[1] - 1, selectedDate.split("-")[2]));

  // Function to return a random emoji from the list
  const randomEmoji = () => {
    const emojis = ['🍾', '🍷', '🎆', '🍻', '🎇', '🥂', '🍸', '🍹'];
    const randomIndex = Math.floor(Math.random() * emojis.length);
    return emojis[randomIndex];
  };

  // Determines if the selected date is today
  const isSelectedDateToday = selectedDate === new Date().toISOString().slice(0, 10);
  
  if (isLoading) {
    return <div></div>;
  }

  return (
    <div className="App">
      <div class="question">
        <h2>Can I bring wine to the Hollywood Bowl?</h2>
      </div>
      <div>
        <input
          type="date"
          id="date-selector"
          value={selectedDate}
          onChange={handleDateChange}
        />
      </div>
      {performance ? (
        <div>
          <h1>
            {performance.ticketing_system_type === "TESSITURA"
              ? `Yes! ${emoji}`
              : performance.ticketing_system_type === "TICKETMASTER"
              ? "Nope"
              : "Couldn't find event."}
          </h1>
          <p>
            <a
              href={performance.performance_url}
              target="_blank"
              rel="noreferrer"
              dangerouslySetInnerHTML={{
                __html: performance.program.name_html.replace(/<br\s*\/?>/gi, " | "),
              }}
            ></a>{" "}
            is at the Hollywood Bowl {isSelectedDateToday ? "tonight" : `on ${formattedSelectedDate}`}.
            <p>
              {performance.ticketing_system_type === "TESSITURA"
                ? "This show is presented by the LA Phil, so outside wine, beer, and alcohol are welcome!"
                : performance.ticketing_system_type === "TICKETMASTER"
                ? "It's a lease event, so outside wine, beer, and alcohol aren't allowed."
                : "This event could not be found."}
            </p>
          </p>
        </div>
      ) : (
        <p>There's no show at the Hollywood Bowl {isSelectedDateToday ? "tonight" : `on ${formattedSelectedDate}`}.
        </p>
      )}
      <div className="footer">
        Always check the Hollywood Bowl <a href="https://www.hollywoodbowl.com/visit/when-youre-here/house-rules" target="_blank" rel="noreferrer">house rules</a> for the most accurate information about any event.
      </div>
    </div>
  );
}

export default App;